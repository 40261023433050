import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../../components/Shared'
import IntegrationsTemplate from '../../components/Integrations'
import helmetProps from '../../components/Integrations/Revit/helmetProps'
import { sanitizedCopy, sanitizedExampleFeatureData } from './utils'

export const query = graphql`
  {
    irisLogo: file(
      relativeDirectory: { eq: "integrations" }
      name: { eq: "1_IrisLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    revitIcon: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "1_RevitLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    revitExportModel: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "1_revit" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 988, maxWidth: 1040, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    revitVRIdea: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "2_revit" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 884, maxHeight: 754, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    revitBIM: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "3_revit" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 560, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    revitMaterials: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "3.2_revit" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 550, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    revitViewpoints: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "3.3_revit" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 550, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    revitFiles: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "3.4_revit" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 992, maxHeight: 592, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    revitPlugin: file(
      relativeDirectory: { eq: "integrations/Revit" }
      name: { eq: "3.5_revit" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 992, maxHeight: 592, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(props => {
  const data = props.data
  /* const images = {
    logos: {
      iris: data.irisLogo,
      integrationCompanyIcon: data.revitIcon,
    },
    ideaMockups: {
      exportModel: data.revitExportModel,
      vrIdea: data.revitVRIdea,
    },
  } test removing images */

  const header = `Unlock the full potential of Revit with true-to-scale BIM design review`
  const subHeader = `The Prospect plugin for Revit`
  const integrationExplanationHeader = `Get the most out of BIM`
  const integrationExplanation = `Prospect makes it easy to perform QA/QC and host design review meetings in a 1:1, true to scale environment. With Prospect + Revit, what you see is what you get—everything in your 3D view is converted directly into VR, including materials, element-level BIM metadata, and more.`
  const sectionLinksHeader = `Prospect directly imports Revit files into VR at the click of a button, with:`
  const copy = {
    header,
    subHeader,
    integrationExplanationHeader,
    integrationExplanation,
    sectionLinksHeader,
  }

  const exampleFeatureData = {
    bim: {
      image: data.revitBIM,
      title: 'BIM object properties',
      description: `All object properties from Revit import directly into Prospect. Use the Inspect Element tool to access any element's BIM metadata with the click of a button, and flag them for later review or reporting.`,
    },
    materials: {
      image: data.revitMaterials,
      title: 'Materials',
      description: `Prospect renders all Revit Materials in VR. See your model how it was meant to be seen—in an immersive, true-to-scale setting, with all Materials retained.`,
    },
    viewpoints: {
      image: data.revitViewpoints,
      title: 'Viewpoints',
      description: `3D Views import directly into VR and automatically become Viewpoints. With Viewpoints, create a narrative for the VR experience and focus on resolving issues in 1:1 scale.`,
    },
    files: {
      image: data.revitFiles,
      title: 'Linked files',
      description: `Prospect supports all linked Revit files—and brings them straight from your 3D view into virtual reality.`,
    },
    plugin: {
      image: data.revitPlugin,
      title: 'Powerful, customizable plugin',
      description: `Our native Revit plugin helps customize every single import to VR. Select exact views and BIM metadata to tailor each VR experience.`,
    },
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <IntegrationsTemplate
        /*images={images} test removing images*/
        copy={sanitizedCopy(copy)}
        exampleFeatureData={sanitizedExampleFeatureData(exampleFeatureData)}
      />
    </>
  )
})
